module directives {
    export module applicationcore {
        interface IRequiredScope extends ng.IScope {
        }

        export class requiredDirective implements ng.IDirective {

            template = `* `;
            scope = {};

            constructor() {
            }

            link = ($scope: IRequiredScope, $element: ng.IAugmentedJQuery) => {

            }

            static factory(): ng.IDirectiveFactory {
                const directive = () => new requiredDirective();
                directive.$inject = [];

                return directive;
            }
        }

        angular.module("app").directive("req", requiredDirective.factory());
    }
}